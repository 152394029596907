import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';
import CPLService from './CplService';

export const AddCourseRelation = () => {
  const [name, setName] = useState('');
  const [startDate, setstartDate] = useState('');
  const [relationType, setrelationType] = useState('');

  const navigate = useNavigate();
  
  const saveCPL = (e) => {
    e.preventDefault();
    CPLService.addCourseRelation({
      course_id: parseInt(name, 10),
      related_course_id: parseInt(startDate, 10),
      relation_type: relationType,
      }).then((resp) => {
        if (resp?.response?.status == 400){
          alert(resp.response.data.message)
        }

        if (resp?.response?.status == 500){
          alert(resp.response.data.message)
        }

        if (!resp.data.error) {
          alert("Successfully add data new courserelation!")
          navigate("/masterdata/courserelation");
        } 
      });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/courserelation'>Course Relation</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Course Relation</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ saveCPL }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course ID</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="Course ID"
            required/>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Related Course ID</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ startDate } 
            onChange={ (e) => setstartDate(e.target.value) }
            placeholder="related course id"
            required/>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Relation Type</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ relationType } 
            onChange={ (e) => setrelationType(e.target.value) }
            placeholder="Co-requisite, Pre-requisite, Hard-requisite"
            required/>
          </div>
        </div>


        <div className="field">
          <Link to={`/masterdata/courserelation`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;

          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>

        </div>
        
      </form>
    </div>
  )
}
