import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import CourseService from '../course/CourseService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { Breadcrumb, Table } from 'react-bootstrap';

export const GradeDetail = () => {
  const [grades, setGrades] = useState([]);

  // params
  const { courseid } = useParams();
  const { userid } = useParams();

  useEffect(() => {
    getGrades();
  }, []);

  const getGrades = async () => {
    let coursesGrades = await CourseService.getCourseGrade(courseid, userid);
    coursesGrades = coursesGrades.data.data;
    setGrades(coursesGrades || []);
  };

  const handleDownloadGrade = async () => {
    try {
      const response = await getReportGrades();

      // Pastikan response adalah Blob
      if (!response || !(response.data instanceof Blob)) {
        console.warn("Received data is not a Blob.");
        alert("The downloaded data is not in the correct format. Please check the server.");
        return;
      }

      if (response.data.size === 0) {
          console.warn("Received an empty file.");
          alert("The downloaded file is empty. Please check the server.");
          return;
      }

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GradeReport.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
        console.error("Failed to download the report:", error);
        alert("Failed to download the report. Please try again.");
    }
  };

  const getReportGrades = async () => {
    try {
      const response = await CourseService.getReportGrade(courseid, userid);
      return response;
    } catch (error) {
      console.log("Error fetching report grade:", error);
      throw error;
    }
  };


  if (grades.length === 0) {
    return (
      <div className="container-fluid">
        <Breadcrumb>
          <Breadcrumb.Item href="/grade">Grade</Breadcrumb.Item>
          <Breadcrumb.Item active>Grade List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Courses Grade</h1>
        </div>
        <div>
          <p className="fs-6 fw-semibold">The grade data is empty, no registered student</p>
        </div>
      </div>
    );
  }

  const grade = grades[0];
  const totalComponentWeight = grade.grade_avg.reduce((sum, section) => sum + section.component, 0);
  const totalAverageComponentGrade = grade.grade_avg.reduce((sum, section) => sum + section.average_component_grade, 0);
  const totalAvgGrade = totalAverageComponentGrade / totalComponentWeight;

  return (
    <div className="container-fluid" id="gradeDetails">
      <Breadcrumb>
        <Breadcrumb.Item href="/grade">Grade</Breadcrumb.Item>
        <Breadcrumb.Item href={`/grade/${courseid}`}>Grade List</Breadcrumb.Item>
        <Breadcrumb.Item active>Grade Details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-md-6">
          <p><strong>Student Name:</strong> {grade.userfullname}</p>
          <p><strong>Student ID:</strong> {grade.userid}</p>
        </div>
        <div className="col-md-6">
          <p><strong>Course Name:</strong> {grade.course_name}</p>
          <p><strong>Total Average Grade Component:</strong> {totalAverageComponentGrade.toFixed(2)} / {totalComponentWeight * 100}</p>
          <p><strong>Total Average Grade:</strong> {totalAvgGrade.toFixed(2)} / 100</p>
        </div>
      </div>

      <h4>Grade Component Averages</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Section Name</th>
            <th>Average Grade</th>
            <th>Component Weight (%)</th>
            <th>Average Component Grade</th>
            <th>Total Average Grade</th>
          </tr>
        </thead>
        <tbody>
          {grade.grade_avg?.map((section, index) => (
            <tr key={index}>
              <td>{section.section_name}</td>
              <td>{section.average_grade}</td>
              <td>{section.component * 100}</td>
              <td>{section.average_component_grade.toFixed(2)}</td>
              <td>-</td>
            </tr>
          ))}
          <tr>
            <td><strong>Total</strong></td>
            <td>-</td>
            <td><strong>{totalComponentWeight.toFixed(2) *100}</strong></td>
            <td><strong>{totalAverageComponentGrade.toFixed(2)}</strong></td>
            <td><strong>{totalAvgGrade.toFixed(2)}</strong></td>
          </tr>
        </tbody>
      </Table>

      <h4>Grade Items</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Item Type</th>
            <th>Module</th>
            <th>Instance ID</th>
            <th>Grade Raw</th>
            <th>Grade Max</th>
            <th>Grade Min</th>
            <th>Section Name</th>
          </tr>
        </thead>
        <tbody>
          {grade.gradeitems?.map((item, index) => (
            <tr key={index}>
              <td>{item.itemname}</td>
              <td>{item.itemtype}</td>
              <td>{item.itemmodule}</td>
              <td>{item.iteminstance}</td>
              <td>{item.graderaw}</td>
              <td>{item.grademax}</td>
              <td>{item.grademin}</td>
              <td>{item.sectionname}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Tombol download PDF */}
      <div className="text-center">
        <button onClick={handleDownloadGrade} className="btn btn-primary mt-4">
        <i className="fas fa-download mr-2"></i> {/* Ikon download */}
          Download PDF
        </button>
      </div>
    </div>
  );
};
