import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import CourseService from '../course/CourseService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { Box, Typography } from '@mui/material';
import { courseFormatParse} from '../../utils/utils';
import StudentService from '../student/StudentService';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';

export const CourseEnrollmentList = () => {
  const [courses, setCourses] = useState([]);
  const [totalCourse, setTotalCourse] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10; // Jumlah item per halaman
  const [categories, setCategories] = useState([]);
  const [showOnlyActive, setShowOnlyActive] = useState(false);

  useEffect( () => {
    getCourses();
    getCategories();
  },[]);

  const getCourses = async () => {
    let courses = await CourseService.getAll();
    courses = courses.data.data 
    setCourses(courses || []);
    setTotalCourse(courses.length)
  }

  const getCategories = async () => {
    try {
      let res = await StudentService.getCategory();
      res = res.data.data;
      setCategories(res)
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }

  const deleteCourse = async (id) => {
    const courses = await CourseService.deleteCourse(id);
    getCourses();

    // Pastikan currentPage valid setelah penghapusan
    const newTotalPages = Math.ceil((courses.length - 1) / itemsPerPage); 
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteCourse(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

   // Filter data siswa berdasarkan pencarian
   const filteredCourse = courses.filter((course) => {
    const matchesSearch = 
      course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.format.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesActiveStatus = showOnlyActive ? course.visible : true;

    return matchesSearch && matchesActiveStatus;
  });

  // Hitung total halaman
  const totalPages = Math.ceil(filteredCourse.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentCourses = filteredCourse.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCategoryByID = (id) => {
    const category = categories.find(cat => cat.mdl_category_id === id);
    return category ? category.name : ''; // Menangani jika ID tidak ada
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  const convertTime = (courseTime) => {
    const dateStart = new Date(courseTime * 1000);
    
    // Menggunakan metode toLocaleString untuk mengonversi ke zona waktu Indonesia (WIB)
    const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',  };
    const indonesiaTime = dateStart.toLocaleString('id-ID', options);
    return indonesiaTime
  } 

  if (courses.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Courses Data</h1>
            <a href="/course/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-chalkboard-teacher"></i> <span>Add New Course</span>
            </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The course data is empty. Please add some new courses !!</p></div>
     </div>
    )    
  }

  return (
      <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Class Course</h1>
          {/* <a href="/course/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-chalkboard-teacher"></i> <span>Add New Course</span>
          </a> */}
      </div>
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              Total Course
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
              {totalCourse}
          </Typography>
      </Box>

      {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search courses...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

       {/* Filter Aktif */}
       <div className='mb-3'>
        <label>
          <input
            type="checkbox"
            checked={showOnlyActive}
            onChange={(e) => setShowOnlyActive(e.target.checked)}
          />{' '}
          Show Only Active Courses
        </label>
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          <th>No</th>
          <th>Course Code</th>
          <th>Course Name</th>
          <th>Format Course</th>
          <th>Course Credit</th>
          <th>Semester</th>
          <th>Category</th>
          <th>Active</th>
          <th>Start Date</th>
          <th>Participants</th>
          </tr>
        </thead>

        <tbody className='table-group-divider'>
          {/* format site dihide */}
          { currentCourses.filter(course => course.format !== 'site').map((course, index) => (
              <tr key={course.idnumber}>
                  <td>{index + 1}</td>
                  <td>{course.course_id_number}</td>
                  <td>{course.name}</td>
                  <td>{courseFormatParse(course.format)}</td>
     
                  <td>{course.course_credit}</td>
                  <td>{course.course_semester}</td>
                  <td>{getCategoryByID(course.category_id)}</td>
                  {course.visible ? (
                    <AiIcons.AiOutlineCheck size={20} color="green" />
                  ) : (
                    <AiIcons.AiOutlineClose size={20} color="red" />
                  )}
                  <td>{convertTime(course.course_start_date)}</td>
                  <td>
                      {/* TODO arahin ke page gradeList by mdlcourse hit ke sini */}
                      <Link title='Detail' to={`/enrollment/`+course.mdl_course_id}>
                        <BiIcons.BiDetail size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;
                      {/* <Link title='Edit' to={`/course/edit/`+course.mdl_course_id}><AiIcons.AiFillEdit size={18} /></Link>&nbsp;&nbsp;&nbsp;
                      <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(course.mdl_course_id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
                  </td>
              </tr>
          )) }
        </tbody>
      </table>

    {/* Pagination Controls */}
    <nav>
      <ul className="pagination">
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>

    {/* Modal konfirmasi */}
    {showConfirm && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <p>Are you sure you want to delete this course?</p>
          <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
          <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
        </div>
      </div>
    )}
    </div>
  )
}
