import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";

const apiKey = 'a60ab88e2afedd6fdf637cdc4be4c12205eb8fe644726c0b9f121b3f0a1044e9';     // Replace with your actual x-api-key

class LecturerService {
    getAll() {
        try {
            return HttpProdi.get("/lecturer");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getLecturerById (lecturerId) {
        try {
            return HttpProdi.get("/lecturer/"+lecturerId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addLecturer = async(lecturer) => {
        try {
            const lecturerArray = [lecturer];
            return await HttpProdi.post("/lecturer",lecturerArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    putLecturer(lecturer, lecturerId) {
        try {
            return HttpProdi.put("/lecturer/"+lecturerId, lecturer);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteLecturer(lecturerId) {
        try {
            return HttpProdi.delete("/lecturer/"+lecturerId,  {
                headers: {
                "X-Api-Key": apiKey, 
                "X-Client_id": 'frontend', 
                "Content-type": "application/json"
              }});
        } catch (error) {
            console.log(error)
            return error
        }
    }

    // manager
    getAllManager() {
        try {
            return HttpProdi.get("/manager");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getManagerById (lecturerId) {
        try {
            return HttpProdi.get("/manager/"+lecturerId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addManager = async(lecturer) => {
        try {
            const lecturerArray = [lecturer];
            return await HttpProdi.post("/manager",lecturerArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }
}

export default new LecturerService();