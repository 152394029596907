/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import StudentService from './StudentService';

const EditStudent = () => {
  const {id} = useParams();
  const [first_name, setFirst_name] = useState('');
  const [middle_name, setMiddle_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [study_program, setStudy_program] = useState('');
  const [degree, setDegree] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');
  const [status, setStatus] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const updateStudent = async (e) => {
    e.preventDefault();
    let suspended = 1 ;
    if (status) {
      suspended = 0 
    } 

    await StudentService.putStudent({
      // first_name: first_name,
      // middle_name: middle_name,
      // last_name: last_name,    
      // study_program: study_program,
      // degree: degree,
      // phone_number: phone_number
      affiliation: affiliation,
      email: email,
      suspended: suspended,
      // username: 
      // password: 
    },id);

    navigate("/student")
  }

  useEffect( () => {
    getStudentById();
  },[]);

  const getStudentById = async () => {
    let res = await StudentService.getStudentById(id);
    res = res.data
    setFirst_name(res.data.firstname);
    setMiddle_name(res.data.middlename);
    setLast_name(res.data.lastname);
    setAffiliation(res.data.affiliation);
    setStudy_program(res.data.study_program);
    setDegree(res.data.degree);
    setEmail(res.data.email);
    setPhone_number(res.data.phone_number);    
    setStatus(res.data.status)
    setUsername(res.data.username)
    setPassword(res.data.password)
  }  

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/student'>Students Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Student</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={ updateStudent }>        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Full Name</label>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ first_name } readOnly
              onChange={ (e) => setFirst_name(e.target.value) }
              placeholder="First Name"
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ middle_name }  readOnly
              onChange={ (e) => setMiddle_name(e.target.value) }
              placeholder="Middle Name"
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ last_name }  readOnly
              onChange={ (e) => setLast_name(e.target.value) }
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Email Address</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ email } 
            onChange={ (e) => setEmail(e.target.value) }
            placeholder="Email"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Username</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ username } 
            onChange={ (e) => setUsername(e.target.value) }
            placeholder="Email"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">password</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ password } 
            onChange={ (e) => setPassword(e.target.value) }
            placeholder="Email"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Affiliation</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ affiliation } 
            onChange={ (e) => setAffiliation(e.target.value) }
            placeholder="Affiliation"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className='col-sm-6'>
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="statusSwitch" 
                checked={status} 
                onChange={() => setStatus(!status)}
              />
              <label className="form-check-label" htmlFor="statusSwitch">
                {status ? 'Active' : 'Inactive'}
              </label>
            </div>
          </div>
        </div>


        {/* <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Department</label>
          <div className='col-sm-2'>
            <select className='form-select'  value={ degree } onChange={ (e) => setDegree(e.target.value) }>
              <option>Degree</option>              
              <option value="DI">Diploma I</option>
              <option value="DII">Diploma II</option>
              <option value="DIII">Diploma III</option>
              <option value="DIV">Diploma IV</option>
              <option value="S1">Bachelor</option>
              <option value="S2">Magister</option>
              <option value="S3">Doctoral</option>
            </select>
          </div>
          <div className='col-sm-4'><input 
            type="text"
            className="form-control"
            value={ study_program } 
            onChange={ (e) => setStudy_program(e.target.value) }
            placeholder="Department or Study Program"
          /></div>
        </div> */}

        {/* <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Phone Number</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ phone_number } 
            onChange={ (e) => setPhone_number(e.target.value) }
            placeholder="Phone Number"
          /></div>
        </div> */}
        
        <div className="field d-flex justify-content-center">
          <Link to={`/student`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateStudent}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </form>
    </div>
  )
}

export default EditStudent