/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom';
import CPLService from './CplService';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import {  Spinner } from 'react-bootstrap';
import { Breadcrumb} from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePlusCircle } from 'react-icons/ai'; // Example icon


export const CPMKbyCPLID = () => {
  const [cpmk, setcpmk] = useState([]);
  const [totalcpmk, settotalcpmk] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [status, setStatus] = useState('');

  const [cpmkname, setcpmkname] = useState();
  const [cpmkcode, setcpmkcode] = useState();
  const [cpmkdesc, setcpmkdesc] = useState();
  
  const { cpl_id } = useParams();
  const itemsPerPage = 10; // Jumlah item per halaman

  const navigate = useNavigate();
  const handleClose = () => setLgShow(false);
  const reload=()=>window.location.reload();
  
  // flag show modal
  const [lgShow, setLgShow] = useState(false);
  const handleShow = () => {
    setLgShow(true);
  }
  const [currentPage, setCurrentPage] = useState(1);

  useEffect( () => {
    if (cpl_id) {
      getCPMKList(cpl_id);
      getCPlByID(cpl_id)
    }
  },[cpl_id]);

  const getCPMKList = async (cpl_id) => {
    const students = await CPLService.getCPMKbyCPLID(cpl_id);
    let res = students?.data?.data
    setcpmk(res || []);
    settotalcpmk(res.length)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Hitung total halaman
  const totalPages = Math.ceil(cpmk.length / itemsPerPage);


  const getCPlByID = async () => {
    let res = await CPLService.getCPLByID(cpl_id);
    res = res.data.data
    setName(res.shortname);
    setDescription(res.description);
    setIdnumber(res.idnumber);
    setStatus(res.visible);
  }  

  const deleteCPMK = async (id) => {
    await CPLService.deleteCPMK(id);
    getCPMKList();
    navigate(`/masterdata/competency/cpmk/${cpl_id}`)
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteCPMK(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };
  
  const saveNewCPMk = async (e) => {
    e.preventDefault();
    try {
      const response = await CPLService.addCPMK({
        competencyframeworkid: parseInt(cpl_id, 10),
        description : cpmkdesc,
        idnumber: cpmkcode,
        shortname: cpmkname,
      });
  
      // Jika respons tidak mengandung error, lanjutkan ke navigasi
      if (!response.data.error) {
        alert("Success add cpmk!");
        getCPMKList();
        setTimeout(() => navigate(`/masterdata/competency/cpmk/${cpl_id}`), 1000);
      } else {
        // Jika ada error di data respons
        console.error("Error in response:", response.data.error);
        alert("Failed to assign lecturer: " + (response.data.error.message || "Unknown error occurred"));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.error("Bad Request:", error.response.data);
          alert("Invalid input data. Please check and try again.");
        } else if (error.response.status === 500) {
          console.error("Server Error:", error.response.data);
          alert(error.response.data.message);
        } else {
          console.error("Unexpected Error:", error.response.data);
          alert("An unexpected error occurred. Please try again.");
        }
      } else {
        console.error("Network Error:", error);
        alert.error("Network error. Please check your internet connection and try again.");
      }
    }
    }


  if (cpmk.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">CPMK</h1>
          <Link to={`/masterdata/competency/cpmk/add/${cpl_id}`} className="btn btn-primary" title="Add New CPMK">
            <AiOutlinePlusCircle size={18} /> {/* Icon */}
            Add New CPMK
          </Link>


        </div>
        <div><p className='fs-6 fw-semibold'>Data is empty. Please wait</p></div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    )    
  } 

  return(
    <div className='container-fluid'>
       <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/competency/cpl'>CPL List</Breadcrumb.Item>
          <Breadcrumb.Item active>CPMK List</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {/* Subsection */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">CPMK (Capaian Pembelajaran Mata Kuliah)</h1>
        <button
            onClick={() => handleShow()}
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          >
            <i className="fas fa-fw fa-user"></i> <span>Add new CPMK</span>
          </button>
      </div>

      <Container>
      <Row> 
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>CPL Name :</b></Col>
            <Col xs={8}>{name}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>CPL Code :</b></Col>
            <Col xs={8}>{idnumber}</Col>
          </Row>
        
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>CPL Description :</b></Col>
            <Col xs={8}>{description}</Col>
          </Row>
        </Col>
      </Row>
      </Container>
      <br></br>
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              CPMK Count List
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
              {totalcpmk}
          </Typography>
      </Box>
          <br></br>
      <table className='table table-sm'>
        <thead>
          <tr>
          <th >
            No
          </th>
          <th>
            Name
          </th>
          <th>
            Code
          </th>
          <th>Description</th>   
          <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
          { cpmk.map((category, index) => (
              <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{category.shortname}</td>
                  <td>{category.idnumber}</td>
                  <td dangerouslySetInnerHTML={{ __html: category.description }}></td>
                  <td>
                      {/* <Link title='Detail' to={`/master-category/`+category.mdl_category_id}>
                        <BiIcons.BiDetail size={18} />
                      </Link> */}
                      {/* &nbsp;&nbsp;&nbsp; */}
                      <Link title='Edit' to={`/masterdata/competency/cpmk/edit/`+category.id+`/`+cpl_id}>
                        <AiIcons.AiFillEdit size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;

                      <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(category.id)}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
              </tr>
          )) }
        </tbody>
      </table>
      
      {/* Modal konfirmasi */}
        {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to delete this cpmk?</p>
              <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        )}
      {/* Pagination Controls */}
      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                {number + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>

<Modal
      size="lg"
      show={lgShow}
      onHide={() => setLgShow(false)}
      onExit={reload}
      aria-labelledby="example-modal-sizes-title-lg"
    >        
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add New CPMK
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={saveNewCPMk} 
      >
      <Modal.Body>      
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">CPL Name</label>
            <div className='col-sm-5'>{name}</div>          
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Short Name</label>
            <div className='col-sm-5'><input 
              type="text"
              className="form-control"
              value={ cpmkname } 
              onChange={ (e) => setcpmkname(e.target.value) }
              placeholder="CPMK short name"
              required/>
            </div>        
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">CPMK Code</label>
            <div className='col-sm-5'><input 
              type="text"
              className="form-control"
              value={ cpmkcode } 
              onChange={ (e) => setcpmkcode(e.target.value) }
              placeholder="CPMK code"
              required/>
            </div>              
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Description</label>
            <div className='col-sm-5'><input 
              type="text"
              className="form-control"
              value={ cpmkdesc } 
              onChange={ (e) => setcpmkdesc(e.target.value) }
              placeholder="CPMK desc"
              required/>
            </div>            
          </div>

      </Modal.Body>

      <Modal.Footer>
        <div className="field">
          <Link className='btn btn-outline-danger btn-sm' onClick={handleClose}><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveNewCPMk}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </Modal.Footer>
      </form>
    </Modal>
    </div>      
  )
}
