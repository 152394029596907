/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Breadcrumb} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StudentService from "./StudentService";

import * as AiIcons from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ViewCategory = () => {
  const {id} = useParams();
  
  const [name, setFirst_name] = useState('');
  const [username, setUsername] = useState('');
  
  const [description, setDescription] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [study_program, setStudy_program] = useState('');


  useEffect( () => {
    getStudentById();
  },[]);

  const getStudentById = async () => {
    let res = await StudentService.getCategoryById(id);
    res = res.data

    // created at
    const dateObject = new Date(res.data.created_at);
    const formattedDate = dateObject.toISOString().slice(0, 10); 
    const formattedTime = dateObject.toTimeString().slice(0, 8); 

    // updatedat
    const dateObjectUpd = new Date(res.data.updated_at);
    const formattedDateUpd = dateObjectUpd.toISOString().slice(0, 10); 
    const formattedTimeUpd = dateObjectUpd.toTimeString().slice(0, 8); 

    setFirst_name(res.data.name);
    setUsername(res.data.id_number);
    setDescription(res.data.description);
    setAffiliation(`${formattedDate}:${formattedTime}`);
    setStudy_program(`${formattedDateUpd}:${formattedTimeUpd}`);

  }  

  const navigate = useNavigate();

  const deleteStudent = async (id) => {
    await StudentService.deleteStudent(id);
    navigate('/master-category');
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/category'>Category Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Category Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>ID Number Category:</b></Col>
          <Col xs={9}>{username}</Col>
        </Row>
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Name :</b></Col>
          <Col xs={9}>{name}</Col>
        </Row>
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Description :</b></Col>
          <Col xs={9}>{description}</Col>
        </Row>
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Created At :</b></Col>
          <Col xs={9}>{affiliation}</Col>
        </Row>
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Updated at :</b></Col>
          <Col xs={9}>{study_program}</Col>
        </Row>

        <Row><Col>&nbsp;</Col></Row>
        <Row>
          <Col className='d-flex justify-content-center'>
            <Link to={`/masterdata/category`} className='btn btn-outline-dark btn-sm'><AiIcons.AiOutlineRollback size={18} />Back</Link>
            &nbsp;&nbsp;
            <Link to={`/master-category/edit/`+id} className='btn btn-outline-primary btn-sm'><AiIcons.AiFillEdit size={18} />Edit</Link>
            &nbsp;&nbsp;
            <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => deleteStudent(id)}><AiIcons.AiTwotoneDelete size={18} />Delete</Link>            
          </Col>
        </Row>  
      </Container>
    </div>
  )
}

export default ViewCategory