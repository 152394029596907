import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
// npm install jwt-decode

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Waktu saat ini dalam detik
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Invalid token:", error);
    return true;
  }
}

export const removeCookiesData = () => {
  Cookies.remove('username');
  Cookies.remove('role_id');
  Cookies.remove('token');
  Cookies.remove('mdl_id');
  Cookies.remove('redirected_mdl_url');
}

export const decodeJWT = (token) => {
    if (!token) {
        console.error("Token tidak ditemukan atau undefined");
        return null; // atau kembalikan nilai default
      }
    
      try {
        // Pisahkan payload dari token
        const payloadBase64 = token.split('.')[1];
      
        // Decode dari Base64 ke string JSON
        const payloadJson = atob(payloadBase64);
      
        // Parse JSON ke objek JavaScript
        const payload = JSON.parse(payloadJson);
      
        // Akses nilai role_id dan mdl_id
        const roleId = payload.role_id;
        const mdlId = payload.mdl_id;
      
        return { roleId, mdlId };
      } catch (error) {
        console.error("Error saat mendekode token:", error);
        return null; // Kembalikan null jika terjadi error
      }
}
  
export const courseFormatParse = (value) => {
    if (value === "topics") {
      return "block";
    }  
    return "weekly";
}
  

