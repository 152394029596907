import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import * as AiIcons from 'react-icons/ai';
import CategoryService from '../category/CategoryService';
import StudentService from '../student/StudentService';
import CourseService from './CourseService';
import * as FiIcons from 'react-icons/fi';
import "react-datepicker/dist/react-datepicker.css"; // Import CSS untuk datepicker

const AddCourseCatalog = () => {
  const [title, setName_id] = useState('');
  const [desc, setDescription] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [course_credit, setCredit] = useState('');
  const [lgShow, setLgShow] = useState(false);
  
  const navigate = useNavigate();
  
  const handleClose = () => setLgShow(false);
  const reload=()=>window.location.reload();

  const saveCourse =  (e) => {
    e.preventDefault();
    let payloadAddCourse = {
      name: title,
      description : desc, 
      course_credit : parseInt(course_credit, 10),
      course_code : courseCode,
    }

    CourseService.addCourseCatalog(payloadAddCourse).then((resp) => {
      if (resp?.response?.status == 400){
        alert(resp.response.data.message)
      }

      if (resp?.response?.status == 500){
        alert(resp.response.data.message)
      }

      if (!resp.data.error) {
        alert("Successfully add data course catalog!")
        navigate("/catalogcourse");
      } 

    });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/catalogcourse'>Courses Catalog Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Course Catalog</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={ saveCourse }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Name</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ title } 
            onChange={ (e) => setName_id(e.target.value) }
            placeholder="Course Name"
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Code</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ courseCode } 
            onChange={ (e) => setCourseCode(e.target.value) }
            placeholder="course code"
            required
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ desc } 
            onChange={ (e) => setDescription(e.target.value) }
            placeholder="description"
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Credit</label>
          <div className='col-sm-5'>
          <select className='form-select'  value={ course_credit } required onChange={ (e) => setCredit(e.target.value) }>
            <option>-Select Course Credits (SKS)-</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        
        {/*
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Curriculum</label>
          <div className='col-sm-10'><input 
            type="text"
            className="form-control"
            value={ curriculum_year } 
            onChange={ (e) => setCurriculum_year(e.target.value) }
            placeholder="Curriculum"
          /></div>
        </div>
        */}

        <div className="field">
          <Link to={`/course`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          {/* <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveCourse}><AiIcons.AiFillSave size={18} />Save</Link> */}
          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>
        </div>
      </form>
    </div>    
  )
}

export default AddCourseCatalog