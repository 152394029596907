import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";

class CategoryService {
    getAll() {
        try {
            return HttpProdi.get("/category");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCategoryById (categoryId) {
        return HttpCommon.get("/category/"+categoryId);
    }

    addCategory(category) {
        try {
            const categoryArray = [category];
            return HttpProdi.post("/category",categoryArray);
        } catch (error) {
            console.log(error)
            return error
        }
        // return HttpCommon.post("/category",category);
    }
}

export default new CategoryService();