/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import StudentService from './StudentService';

const EditCategory = () => {
  const {id} = useParams();
  const [name, setFirst_name] = useState('');
  const [description, setDescription] = useState('');
  const [idnumber, setLast_name] = useState('');

  const navigate = useNavigate();

  const updateStudent = async (e) => {
    e.preventDefault();
    await StudentService.putCategory({
      name: name,
      description: description,
      id_number: idnumber,    
    }, id);

    navigate("/masterdata/category")
  }

  useEffect( () => {
    getStudentById();
  },[]);

  const getStudentById = async () => {
    let res = await StudentService.getCategoryById(id);
    res = res.data
    setFirst_name(res.data.name);
    setDescription(res.data.description);
    setLast_name(res.data.id_number);
  }  

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/category'>Category Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Category</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateStudent }>        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setFirst_name(e.target.value) }
            placeholder="category name"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ description } 
            onChange={ (e) => setDescription(e.target.value) }
            placeholder="description"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">ID Number</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ idnumber } 
            onChange={ (e) => setLast_name(e.target.value) }
            placeholder="id number category"
          /></div>
        </div>

        <div className="field d-flex justify-content-center">
          <Link to={`/masterdata/category`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateStudent}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>

      </form>
    </div>
  )
}

export default EditCategory