import axios from "axios";

// const baseUrlBE = "https://backend.smartprodi.my.id/v1"
const baseLocal = "http://localhost:8000/v1"

const baseUrlBE = process.env.REACT_APP_BASE_URL_BE_V1

export default axios.create({
  baseURL: baseUrlBE,
  // baseURL: baseLocal,
  headers: {
    "Content-type": "application/json"
  }
});