import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CourseService from '../course/CourseService';
import { Breadcrumb, Table } from 'react-bootstrap';
import {  Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export const AttendanceDetail = () => {
  const [grades, setGrades] = useState([]);
  const { courseid, session_id } = useParams(); // Dapatkan courseid dan sessionid dari URL
  const [coursename, setCoursename] = useState('');
  const [courseidnumber, setCourseidnumber] = useState('');
  const [enrolledLecturer, setEnrolledLecturer] = useState([]);

  useEffect(() => {
    getAttendances();
    getEnrollments();
    if (courseid) {
      getCourseById();
    }
  }, []);

  const getCourseById = async () => {
    let res = await CourseService.getCourseById(courseid);
    res = res.data.data
    setCoursename(res.name)
    setCourseidnumber(res.course_id_number)
  }

  const getEnrollments = async () => {
    let coursesGrades = await CourseService.getCourseEnrollment(courseid);
    coursesGrades = coursesGrades.data.data 

    // Filter untuk lecturer dengan roleid 3
    setEnrolledLecturer(coursesGrades.filter((enrollment) => 
      enrollment.roles.some(role => role.roleid === 3)
    ));
  }

  const getLecturersNames = (lecturers) => {
    const lecturerNames = lecturers.map(lecturer => lecturer.fullname);
    
    return `${lecturerNames.join(', ')}`;
  };


  const handleDownloadAttendance = async () => {
    try {
      const response = await getReportAttendances();
      console.log("response :", response)
      // Pastikan response adalah Blob
      if (!response || !(response.data instanceof Blob)) {
        console.warn("Received data is not a Blob.");
        alert("The downloaded data is not in the correct format. Please check the server.");
        return;
      }

      if (response.data.size === 0) {
          console.warn("Received an empty file.");
          alert("The downloaded file is empty. Please check the server.");
          return;
      }

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'AttendanceReport.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
        console.error("Failed to download the report:", error);
        alert("Failed to download the report. Please try again.");
    }
  };

  // BAP
  const getReportAttendances = async () => {
    try {
      const response = await CourseService.getReportAttendance(courseid, session_id);
      return response;
    } catch (error) {
      console.log("Error fetching report grade:", error);
      throw error;
    }
  };


  const getAttendances = async () => {
    try {
      let response = await CourseService.getCourseAttendance(courseid, 0);
      const allGrades = response.data.data || [];

      const numericSessionId = parseInt(session_id, 10);
      // Filter data untuk hanya menyimpan sesi yang memiliki session_id yang sesuai
      const filteredGrades = allGrades.filter(session => session.session_id === numericSessionId);
     
      setGrades(filteredGrades);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  if (grades.length === 0) {
    return (
      <div className='container-fluid'>
        <Breadcrumb>
          <Breadcrumb.Item href='/grade'>Grade</Breadcrumb.Item>
          <Breadcrumb.Item active>Attendance List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Attendance Details</h1>
        </div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <Breadcrumb>
        <Breadcrumb.Item href="/attendancecourse">Attendance</Breadcrumb.Item>
        <Breadcrumb.Item href={`/attendancecourse/${courseid}`}>Attendance List</Breadcrumb.Item>
        <Breadcrumb.Item active>Attendance Details</Breadcrumb.Item>
      </Breadcrumb>

      <h4>Attendance Session Details</h4>
      <Container>
      <Row> 
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={8}>{coursename}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Code :</b></Col>
            <Col xs={8}>{courseidnumber}</Col>
          </Row>
        
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Lecturer :</b></Col>
            <Col xs={8}>{getLecturersNames(enrolledLecturer)}</Col>
          </Row>
        </Col>
      </Row>
      </Container>
      <br></br>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Class Date</th>
            <th>Class Schedule</th>
            <th>Class Start Time</th>
            <th>Duration (minutes)</th>
            <th>Number Present</th>
            <th>Class Participant</th>
            <th>Course Material</th>
          </tr>
        </thead>
        <tbody>
          {grades.map((session, index) => (
            <React.Fragment key={session.session_id}>
              <tr>
                <td>{session.class_date}</td>
                <td>{session.class_schedule}</td>
                <td>{session.class_start_time}</td>
                <td>{(session.duration / 60)}</td> {/* Convert seconds to hours */}
                <td>{session.number_present}</td>
                <td>{session.class_participant}</td>
                <td>{session.course_material}</td>
              </tr>
              <br></br><br></br>
              {/* Detail Attendance for Each Student in this Session */}
              <tr>
                <td colSpan="7">
                  <h5>Attendance List</h5>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {session.attendance_list_detail.map((student, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{student.student_name}</td>
                          <td>{student.mdl_user_id}</td>
                          <td>{student.status}</td>
                          <td>{student.remarks || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <div className="text-center">
        <button onClick={handleDownloadAttendance} className="btn btn-primary mt-4">
        <i className="fas fa-download mr-2"></i> {/* Ikon download */}
          Download PDF
        </button>
      </div>
    </div>
  );
};
