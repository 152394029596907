import HttpProdi from "../../HttpSmartProdi";

class CPLService {
    getCPLList() {
        try {
            return HttpProdi.get("/competency/cpl");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getLearningPeriods() {
        try {
            return HttpProdi.get("/learningperiod");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteCPL(cplID) {
        try {
            return HttpProdi.delete("/competency/cpl/"+cplID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteLearningPeriod(cplID) {
        try {
            return HttpProdi.delete("/learningperiod/"+cplID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteCourseRelation(id) {
        try {
            return HttpProdi.delete("/courserelation/"+id);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCourseRelations() {
        try {
            return HttpProdi.get("/courserelation");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addCourseRelation = async(data) => {
        try {
            return await HttpProdi.post("/courserelation", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    editCourseRelation( data, cpmkID) {
        try {
            return HttpProdi.put("/courserelation/"+cpmkID, data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCourseRelationByID(cplID) {
        try {
            return HttpProdi.get("/courserelation/"+cplID);
        } catch (error) {
            console.log(error)
            return error
        }
    }


    getCPLByID(cplID) {
        try {
            return HttpProdi.get("/competency/cpl/"+cplID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getLearningPeriodByID(cplID) {
        try {
            return HttpProdi.get("/learningperiod/"+cplID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    editCPLByID( data,cplID) {
        try {
            return HttpProdi.put("/competency/cpl/"+cplID, data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    editLearningPeriodByID(data,cplID) {
        try {
            return HttpProdi.put("/learningperiod/"+cplID, data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addCPL = async(data) => {
        try {
            return await HttpProdi.post("/competency/cpl", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addLearningPeriod = async(data) => {
        try {
            return await HttpProdi.post("/learningperiod", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCPMKbyCPLID(cplID) {
        try {
            return HttpProdi.get("/competency/cpmk/"+cplID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addCPMK = async(data) => {
        try {
            return await HttpProdi.post("/competency/cpmk", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addCPMKtoCourse = async(data) => {
        try {
            return await HttpProdi.post("/competency/cpmk/course", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteCPMK(cpmkID) {
        try {
            return HttpProdi.delete("/competency/cpmk/"+cpmkID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    editCPMK( data, cpmkID) {
        try {
            return HttpProdi.put("/competency/cpmk/"+cpmkID, data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCPMKbyCourseID(courseID) {
        try {
            return HttpProdi.get("/competency/cpmk/course/"+courseID);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    removeCPMKtoCourse = async(data) => {
        try {
            return await HttpProdi.post("/competency/cpmk/course/remove", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

}

export default new CPLService();