/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import LecturerService from './LecturerService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';

const ManagerList = () => {
  const [lecturers, setLecturers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrderAff, setSortOrderAffiliation] = useState('asc');
  const itemsPerPage = 10; // Jumlah item per halaman

  useEffect( () => {
    getLecturers();
  },[]);

  const getLecturers = async () => {
    const lecturers = await LecturerService.getAllManager();
    setLecturers(lecturers.data.data || []);
  }

  const deleteLecturer = async (id) => {
    await LecturerService.deleteLecturer(id);
    getLecturers();
  }

  // Filter data siswa berdasarkan pencarian
  const filteredLecturer = lecturers.filter((student) => {
    return (
      student.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.affiliation.toLowerCase().includes(searchTerm.toLowerCase()) || 
      student.academic_position.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortByAffiliation = () => {
    const sortedStudentsAff = [...lecturers].sort((a, b) => {
      if (sortOrderAff === 'asc') {
        return a.affiliation.localeCompare(b.affiliation);
      } 
      return b.affiliation.localeCompare(a.affiliation);
    });
    setLecturers(sortedStudentsAff);
    setSortOrderAffiliation(sortOrderAff === 'asc' ? 'desc' : 'asc'); // Toggle urutan sortir
  };
  
  // Hitung total halaman
  const totalPages = Math.ceil(filteredLecturer.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentLecturer = filteredLecturer.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (lecturers.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Manager Data</h1>
          <a href="/manager/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user-tag"></i> <span>Add New Manager</span>
          </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The Manage data is empty. Please add some new managers !!</p></div>
      </div>
    )    
  } 

  return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Manager Data</h1>
          <a href="/manager/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user-tag"></i> <span>Add New Manager</span>
          </a>
        </div>

        {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search managers...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

        <table className='table table-sm'>
          <thead>
            <tr>
            <th>National ID (NIDN)</th>
            <th>Full Name</th>
            <th onClick={sortByAffiliation} style={{ cursor: 'pointer' }}>
              Affiliation {sortOrderAff === 'asc' ? <AiIcons.AiOutlineArrowUp /> : <AiIcons.AiOutlineArrowDown />}
            </th>   
            <th>Academic Position</th>   
            <th>Email</th>                 
            <th>Actions</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            { currentLecturer.map((lecturer, index) => (
                <tr key={lecturer.mdl_id}>
                    <td>{lecturer.username}</td>
                    <td>
                      { 
                        `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`.length > 40
                        ? `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`.substring(0, 45) + "..." 
                        : `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`
                      }
                    </td>
                    <td>{lecturer.affiliation}</td>   
                    <td>{lecturer.academic_position}</td>   
                    <td>{lecturer.email}</td>                
                    <td>
                        <Link title='Detail' to={`/manager/`+lecturer.mdl_id}><BiIcons.BiDetail size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        <Link title='Edit' to={`/manager/edit/`+lecturer.mdl_id}><AiIcons.AiFillEdit size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        {/* <Link title='Delete' onClick={()=>deleteLecturer(lecturer.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
                    </td>
                </tr>
            )) }
          </tbody>
        </table>

        {/* Pagination Controls */}
      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                {number + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>      
  )
}

export default ManagerList