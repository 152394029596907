/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';
import CourseService from './CourseService';
import CategoryService from '../category/CategoryService';

const EditCourseCatalog = () => {
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(false);
  const [credit, setCredit] = useState();
  const [courseCode, setCoursecode] = useState();
  const [description, setDescription] = useState('');
  const [gradeid, setGradeid] = useState();

  const navigate = useNavigate();
  const {id} = useParams();

  useEffect( () => {
    getCourseById();
  },[]);

  const getCourseById = async () => {
    let res = await CourseService.getCourseCatalogById(id);
    res = res.data.data

    setTitle(res.name)
    setDescription(res.description)
    setCredit(res.course_credit)
    setStatus(res.status)
    setCoursecode(res.courseCode)
  }

  const updateCourse = async (e) => {
    e.preventDefault();

    let payload = {
      name: title,
      description : description, 
      course_credit : parseInt(credit, 10),
      status : status,
      course_code : courseCode,
    }
    
    CourseService.putCourseCatalog(payload, id).then((resp) => {
      if (resp?.response?.status == 400){
        alert(resp.response.data.message)
      }

      if (resp?.response?.status == 500){
        alert(resp.response.data.message)
      }

      if (!resp.data.error) {
        alert("Successfully edited course catalog!")
        navigate("/catalogcourse");
      } 

    });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/catalogcourse'>Course catalog Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Course catalog</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateCourse }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Name</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ title } 
            onChange={ (e) => setTitle(e.target.value) }
            placeholder="Course Name"
          />
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Codes</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ courseCode } 
            onChange={ (e) => setCoursecode(e.target.value) }
            placeholder="Course code"
          />
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ description } 
            onChange={ (e) => setDescription(e.target.value) }
            placeholder="Course Short Name "
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Credit</label>
          <div className='col-sm-5'>
          <select className='form-select'  value={ credit } required onChange={ (e) => setCredit(e.target.value) }>
            <option>-Select Course Credits (SKS)-</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="field">
          <Link to={`/catalogcourse`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateCourse}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </form>
    </div>
  )
}

export default EditCourseCatalog