import React, {useState, useEffect} from 'react';
import { useNavigate , useParams, Link} from 'react-router-dom';
import CourseService from '../course/CourseService';

import { Box, Typography } from '@mui/material';
import { Breadcrumb} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Spinner } from 'react-bootstrap';
import OfferedcourseService from '../offeredcourse/OfferedcourseService';
import Modal from 'react-bootstrap/Modal';
import * as AiIcons from 'react-icons/ai';
import StudentService from '../student/StudentService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EnrollmentListV2 = () => {
  const [totalParticipant, setTotalParticipant] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Jumlah item per halaman

  const [studentid, setStudentid] = useState('');
  const [students, setStudents] = useState([]);

  const [coursename, setCoursename] = useState('');
  const [courseidnumber, setCourseidnumber] = useState('');
  const [courseSummary, setCourseSummary] = useState('');
  const [courseCredit, setCourseCredit] = useState();

  // enrolled
  const [enrolledstudents, setEnrolledstudents] = useState([]);
  const [enrolledLecturer, setEnrolledLecturer] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedUserID, setselectedUserID] = useState();
  const [selectedCourseID, setselectedCourseID] = useState();

  // param
  const {courseid} = useParams();
  
  const navigate = useNavigate();
  const handleClose = () => setLgShow(false);
  const reload=()=>window.location.reload();
  
  // flag show modal
  const [lgShow, setLgShow] = useState(false);

  const handleShow = () => {
    setLgShow(true);
  }

  useEffect( () => {
    getEnrollments();
    getStudents();
    if (courseid) {
      getCourseById();
    }
  },[]);

  const getCourseById = async () => {
    let res = await CourseService.getCourseById(courseid);
    res = res.data.data
    setCoursename(res.name)
    setCourseidnumber(res.course_id_number)
    setCourseSummary(res.summary)
    setCourseCredit(res.course_credit)
  }

  const getEnrollments = async () => {
    let coursesGrades = await CourseService.getCourseEnrollment(courseid);
    coursesGrades = coursesGrades.data.data 

    // Filter untuk student dengan roleid 5
    const enrolledStudents = (coursesGrades.filter((enrollment) => 
      enrollment.roles.some(role => role.roleid === 5) 
    ));

    setEnrolledstudents(enrolledStudents)
    setTotalParticipant(enrolledStudents.length)

    // Filter untuk lecturer dengan roleid 3
    setEnrolledLecturer(coursesGrades.filter((enrollment) => 
      enrollment.roles.some(role => role.roleid === 3)
    ));
   
  }

  const getStudents = async () => {
    const students = await StudentService.getAll();
    setStudents(students.data.data || []);
  }

  const saveOfferedcourse = async (e) => {
    e.preventDefault();
    try {
      const response = await OfferedcourseService.addEnrolledCourse({
        course_id: parseInt(courseid, 10),
        user_id: parseInt(studentid, 10),
        role_id: 5
      });
  
      // Jika respons tidak mengandung error, lanjutkan ke navigasi
      if (!response.data.error) {
        toast.success("Success enroll student!");
        setTimeout(() => navigate(`/enrollment/${courseid}`), 2000);
      } else {
        // Jika ada error di data respons
        console.error("Error in response:", response.data.error);
        toast.error("Failed to assign lecturer: " + (response.data.error.message || "Unknown error occurred"));
      }
    } catch (error) {
      // Tangani error spesifik berdasarkan kode status
      if (error.response) {
        if (error.response.status === 400) {
          console.error("Bad Request:", error.response.data);
          toast.error("Invalid input data. Please check and try again.");
        } else if (error.response.status === 500) {
          console.error("Server Error:", error.response.data);
          toast.error(error.response.data.message);
        } else {
          console.error("Unexpected Error:", error.response.data);
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else {
        // Tangani error lainnya seperti masalah jaringan
        console.error("Network Error:", error);
        toast.error("Network error. Please check your internet connection and try again.");
      }
    }
  }

  const getLecturersNames = (lecturers) => {
    const lecturerNames = lecturers.map(lecturer => lecturer.fullname);
    return `${lecturerNames.join(', ')}`;
  };

  const unenroll = async (course_id, user_id) => {
    try {
      const responseUnenroll = await OfferedcourseService.unEnrollCourse(course_id, user_id);
      if (responseUnenroll.data.status_code === 201) {
        toast.success("successfully unenroll student")
        navigate(`/enrollment/${courseid}`); // Navigasi ke halaman lain jika berhasil
      } else {
        console.error("Unenroll failed:", responseUnenroll.data.message || "Unknown error occurred");
        toast.error("Failed to unenroll lecturer: " + (responseUnenroll.data.message || "Unknown error occurred"));
        navigate("/");
      }
    } catch (error) {
      // Tangani error jaringan atau lainnya
      console.error("Error during unenroll:", error);
      toast.error("An error occurred while trying to unenroll the lecturer. Please try again later.");
    }
  }

  // Hitung total halaman
  const totalPages = Math.ceil(enrolledstudents.length / itemsPerPage);

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleUnenroll = (course_id, user_id) => {
    setselectedUserID(user_id);
    setselectedCourseID(course_id);
    setShowConfirm(true);
  };

  const handleConfirmUnenroll = () => {
    if (selectedCourseID && selectedUserID)  {
      unenroll(selectedCourseID, selectedUserID);
    }
    setShowConfirm(false);
  };

  const handleCancelUnenrolle = () => {
    setShowConfirm(false);
    setselectedCourseID(null);
    setselectedUserID(null);
  };

  // Calculate the items to be displayed on the current page
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const currentStudents = enrolledstudents.slice(startIndex, endIndex);


  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (enrolledstudents.length === 0) {
    return (
      <div className='container-fluid'>
        <Breadcrumb>
          <Breadcrumb.Item href='/enrollment/student'>Course List</Breadcrumb.Item>
          <Breadcrumb.Item active>Course Participant List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Courses Participant Kist</h1>
        </div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    );
  }

  return (
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Course Participant List</h1>
          <button
            onClick={() => handleShow()}
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          >
            <i className="fas fa-fw fa-user"></i> <span>Enroll Student to Course</span>
          </button>
        </div>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/enrollment/student'>Course List</Breadcrumb.Item>
          <Breadcrumb.Item active>Course Participant List</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
      </div>

      <Container>
      <Row> 
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={8}>{coursename}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Code :</b></Col>
            <Col xs={8}>{courseidnumber}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Summary :</b></Col>
            <Col xs={8}>{courseSummary}</Col>
          </Row>
        
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Lecturer :</b></Col>
            <Col xs={8}>{getLecturersNames(enrolledLecturer)}</Col>
          </Row>
          <br/>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Credit (SKS) :</b></Col>
            <Col xs={8}>{courseCredit}</Col>
          </Row>
        </Col>
      </Row>
      </Container>
      <br></br>
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              Course Participant List
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
             {totalParticipant}
          </Typography>
      </Box>

      <table className='table table-sm'>
      <thead>
        <tr>
          <th>No</th>
          <th>Student ID</th>
          <th>Student Name</th>
          <th>Student Email</th>
          <th>Study Program</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody className='table-group-divider'>
        {currentStudents.map((enroll, index) => {
          return (
            <tr key={enroll.courseid}>
              <td>{index + 1}</td>
              <td>{enroll.username}</td>
              <td>{enroll.fullname}</td>
              <td>{enroll.email}</td>
              <td>{enroll.department}</td>  
              <td> 
                  <button className='btn btn-primary btn-sm' onClick={ () => handleUnenroll(courseid, enroll.id) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                      <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                    </svg>
                    Un-enroll 
                  </button>                        
                </td>
            </tr>
          );
        })}
      </tbody>
    </table>

    {/* Modal konfirmasi */}
    {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to unenroll this student?</p>
              <button className="btn btn-danger" onClick={handleConfirmUnenroll}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelUnenrolle}>Cancel</button>
            </div>
          </div>
        )}

    {/* Pagination Controls */}
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        </li>
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </li>
      </ul>
    </nav>

    <Modal
      size="lg"
      show={lgShow}
      onHide={() => setLgShow(false)}
      onExit={reload}
      aria-labelledby="example-modal-sizes-title-lg"
    >        
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add Student Participant
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={saveOfferedcourse} 
      >
      <Modal.Body>          
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Name</label>
            <div className='col-sm-5'>{coursename}</div>          
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Code</label>
            <div className='col-sm-5'>{courseidnumber}</div>          
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course ID</label>
            <div className='col-sm-5'>{courseid}</div>          
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Student to Enroll</label>
            <div className='col-sm-5'>
              <select className='form-select' value={ studentid } onChange={ (e) => setStudentid(e.target.value) }>
                <option>-Select student-</option>
                {students.map((student, index) => {
                return (
                <option key={index} value={student.mdl_id}>
                  {`${student.username} ( ${student.firstname} ${student.middlename} ${student.lastname} )`}
                </option>
                )
              })}
              </select>
            </div>
          </div>          
      </Modal.Body>

      <Modal.Footer>
        <div className="field">
          <Link className='btn btn-outline-danger btn-sm' onClick={handleClose}><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveOfferedcourse}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </Modal.Footer>
      </form>
    </Modal>

    <ToastContainer />
    </div>
  )
}
