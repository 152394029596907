import React, {useState,useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import StudentService from './StudentService';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';


const AddStudent = () => {
  const [student_id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [middle_name, setMiddle_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [study_program, setStudy_program] = useState('');
  const [degree, setDegree] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [studyProgram, setStudyProgram] = useState([]);
  useEffect( () => {
    getStudyProgram();
  },[]);

  const getStudyProgram = async () => {
    let categories = await StudentService.getStudyProgram();
    categories = categories.data.data
    setStudyProgram(categories || []);
  }

  const navigate = useNavigate();
  const saveStudent = (e) => {
  e.preventDefault();

  StudentService.addStudent({
    student_id: student_id,
    username: student_id,
    password: password, 
    first_name: first_name,
    middle_name: middle_name,
    last_name: last_name,    
    affiliation: affiliation,
    study_program: study_program,
    degree: degree,
    email: email,
    phone_number: phone_number
  }).then((resp) => {

    if (resp?.response?.status == 400){
      alert(resp.response.data.message)
    }

    if (resp?.response?.status == 500){
      alert(resp.response.data.message)
    }

    if (!resp.data.error) {
      alert("Successfully add data student!")
      navigate("/student");
    } 

  });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/student'>Students Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Student</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={ saveStudent }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Student ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ student_id } 
            onChange={ (e) => setId(e.target.value) }
            placeholder="Student ID"
            required
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Username</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ student_id } 
            onChange={ (e) => setUsername(e.target.value) }
            placeholder="username"
            required
            readOnly
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Password</label>
          <div className='col-sm-6 '>
            <div className='input-group'>
            <input 
            type={showPassword ? "text" : "password"}
            className="form-control"
            value={ password } 
            onChange={ (e) => setPassword(e.target.value) }
            placeholder="password"
            required
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
          </button>
            </div>
    
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Full Name</label>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ first_name } 
              onChange={ (e) => setFirst_name(e.target.value) }
              placeholder="First Name"
              required
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ middle_name } 
              onChange={ (e) => setMiddle_name(e.target.value) }
              placeholder="Middle Name"
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ last_name } 
              onChange={ (e) => setLast_name(e.target.value) }
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Affiliation</label>
          <div className='col-sm-2'>
            <select className='form-select'  value={ affiliation } required onChange={ (e) => setAffiliation(e.target.value) }>
            <option>-Select affiliation-</option>
              <option value="UKTS">UKTS</option>
              <option value="ITB">ITB</option>
              <option value="ITB">UNS</option>
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Department</label>
          <div className='col-sm-2'>
            <select className='form-select'  value={ degree } required onChange={ (e) => setDegree(e.target.value) }>
            <option>-Select degree-</option>
              <option value="DI">Diploma I</option>
              <option value="DII">Diploma II</option>
              <option value="DIII">Diploma III</option>
              <option value="DIV">Diploma IV</option>
              <option value="S1">Bachelor</option>
              <option value="S2">Magister</option>
              <option value="S3">Doctoral</option>
            </select>
          </div>

          <div className='col-sm-4'>
            <select className='form-select' value={ study_program } required onChange={ (e) => setStudy_program(e.target.value) }>
              <option>-Select Study Program-</option>
              {studyProgram?.map((gc, index) => {
                return (
                  <option key={index} value={gc.name}>
                  {gc.name}
                  </option>
                )
              })}              
            </select>
          </div>
        </div>
        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Email Address</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ email } 
            onChange={ (e) => setEmail(e.target.value) }
            placeholder="Email"
            required
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Phone Number</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ phone_number } 
            onChange={ (e) => setPhone_number(e.target.value) }
            placeholder="Phone Number"
            required
          /></div>
        </div>

        <div className="field">
          <Link to={`/student`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;

          {/* <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveStudent}><AiIcons.AiFillSave size={18} />Save</Link> */}
          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>

        </div>
        
      </form>
    </div>
  )
}

export default AddStudent