/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import CPLService from './CplService';

export const EditLearningPeriod = () => {
  const [name, setName] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [status, setstatus] = useState('');
  
  const navigate = useNavigate();
  
  const {id} = useParams();
  
  useEffect( () => {
    getCPlByID();
  },[]);
  
  const getCPlByID = async () => {
    let res = await CPLService.getLearningPeriodByID(id);
    res = res.data.data
    setName(res.name);
    setstartDate(res.start_date);
    setendDate(res.end_date);
    setstatus(res.status)
  }  
  
  const updateCPL = async (e) => {
    e.preventDefault();
    await CPLService.editLearningPeriodByID({
      shortname: name,
      start_date: startDate,
      end_date: endDate,    
      status: status
    }, id);
    navigate("/masterdata/learningperiod")
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/learningperiod'>Learning Period Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit LearningPeriod</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateCPL }>        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="short name cpl"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Start date</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ startDate } 
            onChange={ (e) => setstartDate(e.target.value) }
            placeholder="start date"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">End date</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ endDate } 
            onChange={ (e) => setendDate(e.target.value) }
            placeholder="end date"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className='col-sm-6'>
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="statusSwitch" 
                checked={status} 
                onChange={() => setstatus(!status)}
              />
              <label className="form-check-label" htmlFor="statusSwitch">
                {status ? 'Active' : 'Inactive'}
              </label>
            </div>
          </div>
        </div>

        <div className="field d-flex justify-content-center">
          <Link to={`/masterdata/learningperiod`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateCPL}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>

      </form>
    </div>
  )
}
