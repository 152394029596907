/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import CPLService from './CplService';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import {  Spinner } from 'react-bootstrap';

export const CplList = () => {
  const [cpl, setCPL] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const navigate = useNavigate();
  const itemsPerPage = 10; // Jumlah item per halaman

  useEffect( () => {
    getCPLList();
  },[]);

  const [currentPage, setCurrentPage] = useState(1);

  const getCPLList = async () => {
    const students = await CPLService.getCPLList();
    setCPL(students.data.data || []);
  }

  const deleteCPL = async (id) => {
    await CPLService.deleteCPL(id);
    getCPLList();
    navigate("/masterdata/competency/cpl")
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
        deleteCPL(selectedCourseId);
    }
    setShowConfirm(false);
  };

  // Filter data siswa berdasarkan pencarian
  // const filteredCourse = cpl.filter((course) => {
  //   return (
  //     // course.idnumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     course.format.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });

  // Hitung total halaman
  const totalPages = Math.ceil(cpl.length / itemsPerPage);


  // Ambil data yang sesuai dengan halaman saat ini
  const currentCourses = cpl.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (cpl.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">CPL</h1>
          <a href="/masterdata/competency/cpl/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New CPL</span>
        </a>
        </div>
        <div><p className='fs-6 fw-semibold'>Data is empty. Please wait</p></div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    )    
  } 

  return(
    <div className='container-fluid'>
      {/* Subsection */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">CPL (Capaian Pembelajaran Lulusan)</h1>
        <a href="/masterdata/competency/cpl/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New CPL</span>
        </a>
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          {/* <th>
            ID
          </th> */}

          <th >
            No
          </th>
          <th>
            Name
          </th>
          <th>
            Code
          </th>
          <th>Description</th>   
          <th>
            Status
          </th>         
          <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
          { currentCourses.map((category, index) => (
              <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{category.shortname}</td>
                  <td>{category.idnumber}</td>
                  <td dangerouslySetInnerHTML={{ __html: category.description }}></td>
                  <td>
                    {category.visible ? (
                      <AiIcons.AiOutlineCheck size={20} color="green" />
                    ) : (
                      <AiIcons.AiOutlineClose size={20} color="red" />
                    )}
                  </td>
                  <td>
                      <Link title='Detail' to={`/masterdata/competency/cpmk/`+category.id}>
                        <BiIcons.BiDetail size={18} />
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <Link title='Edit' to={`/masterdata/competency/cpl/edit/`+category.id}>
                        <AiIcons.AiFillEdit size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;

                      <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(category.id)}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
              </tr>
          )) }
        </tbody>
      </table>
      
      {/* Modal konfirmasi */}
        {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to delete this cpl?</p>
              <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        )}
      {/* Pagination Controls */}
      <nav>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
          </li>
          {[...Array(totalPages).keys()].map((number) => (
            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                {number + 1}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>      
  )
}
