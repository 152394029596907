/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import CPLService from './CplService';

export const EditCPMK = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [idnumber, setIdnumber] = useState('');
  
  const navigate = useNavigate();
  
  const {cpmk_id, cpl_id} = useParams();
  
  useEffect( () => {
    if (cpl_id) {
      getCPMKList()
    }
  },[]);

  const getCPMKList = async () => {
    const students = await CPLService.getCPMKbyCPLID(cpl_id);
    let res = students.data.data || []

    let cpmk_idInt = parseInt(cpmk_id, 10)
    let filteredData = res.find(item => item.id === cpmk_idInt);

    setName(filteredData.shortname)
    setDescription(filteredData.description)
    setIdnumber(filteredData.idnumber)
  }
  
  const updateCPMK = async (e) => {
    e.preventDefault();
    await CPLService.editCPMK({
      shortname: name,
      description: description,
      idnumber: idnumber,    
    }, cpmk_id);
    navigate(`/masterdata/competency/cpmk/${cpl_id}`)
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/competency/cpl'>CPL Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit CPMK</Breadcrumb.Item>
          <Breadcrumb.Item active>{cpmk_id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateCPMK }>        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Short Name</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="short name cpl"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ description } 
            onChange={ (e) => setDescription(e.target.value) }
            placeholder="description"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">CPMK ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ idnumber } 
            onChange={ (e) => setIdnumber(e.target.value) }
            placeholder="id number cpl"
          /></div>
        </div>

        <div className="field d-flex justify-content-center">
          <Link to={`/masterdata/competency/cpmk/${cpl_id}`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateCPMK}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>

      </form>
    </div>
  )
}
