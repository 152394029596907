import React from 'react'

function Header() {
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <h1>&nbsp;&nbsp; Smart Learning System Administration</h1>
    </nav>
    
  )
}

export default Header