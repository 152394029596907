import React, {useState} from 'react';
import {useNavigate, Link, useParams} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';
import CPLService from './CplService';

export const AddCPMK = () => {
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [idNumber, setidNum] = useState('');

  const navigate = useNavigate();
  const { cpl_id } = useParams();

  const saveCPL = (e) => {
    e.preventDefault();

    CPLService.addCPMK({
      shortname: name,
      description: desc,
      idnumber: idNumber,
      competencyframeworkid: parseInt(cpl_id, 10)
      }).then((resp) => {
        if (resp?.response?.status == 400){
          alert(resp.response.data.message)
        }

        if (resp?.response?.status == 500){
          alert(resp.response.data.message)
        }

        if (!resp.data.error) {
          alert("Successfully add data new CPMK!")
          navigate("/masterdata/competency/cpl");
        } 
      });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/competency/cpl'>CPMK Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New CPMK</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ saveCPL }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Short Name</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="Short Name CPMK"
            required/>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">CPL Code</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ idNumber } 
            onChange={ (e) => setidNum(e.target.value) }
            placeholder="CPMK id Number"
            required/>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ desc } 
            onChange={ (e) => setDesc(e.target.value) }
            placeholder="Description"
            required/>
          </div>
        </div>


        <div className="field">
          <Link to={`/masterdata/competency/cpl`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;

          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>

        </div>
        
      </form>
    </div>
  )
}
