/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as AiIcons from 'react-icons/ai';

import StudentService from './StudentService';

const EditGradeComponent = () => {
  const {id} = useParams();
  const [name, setName] = useState('');
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialComponent = {
    uts: 0.2,
    uas: 0.2,
    proyek: 0.15,
    studi_kasus: 0.15,
    quiz: 0.15,
    partisipasi: 0.15
  };
  const [component, setComponent] = useState(initialComponent);
  const navigate = useNavigate();

  const handleInputChange = (e, field) => {
    let value = parseFloat(e.target.value) || 0
    if (value > 1) {
      setErrorMessage(`Value for ${field} cannot exceed 1.0`);
      // value = 10; // Set nilai ke 1 jika pengguna memasukkan nilai lebih besar dari 1
    } else {
      setErrorMessage(''); // Reset error jika validasi lolos
    }
    
    setComponent({
      ...component,
      [field]: value 
    });
  };

  const updateStudent = async (e) => {
    e.preventDefault();
    const total = Object.values(component).reduce((sum, value) => sum + value, 0);

    if (total !== 1.00) {
      setErrorMessage(`Total value of components must equal 1.0. Current total is ${total.toFixed(2)}.`);
      return; // Jika total tidak sama dengan 1.0, hentikan proses submit
    }
    setErrorMessage('');

    e.preventDefault();
    await StudentService.putGradeComponent({
      name: name,
      is_active: status,
      component: component,
    },id);

    alert("successfully edited!")
    navigate("/gradecomponents")
  }

  useEffect( () => {
    getStudentById();
  },[]);

  const getStudentById = async () => {
    let res = await StudentService.getGradeComponentById(id);
    res = res.data
    setName(res.data.name);
    setStatus(res.data.is_active);
    setComponent(res.data.component);
  }  

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/gradecomponents'>Grade Components</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Grade Component</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateStudent }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="Grade Component Name"
            required/>
          </div>
        </div>

        {Object.keys(component).map((key) => (
        <div className="mb-3 row" key={key}>
          <label className="col-sm-2 col-form-label">{key.toUpperCase()}</label>
          <div className="col-sm-6">
            <input
              type="number"
              step="0.01"
              className="form-control"
              value={component[key]}
              onChange={(e) => handleInputChange(e, key)}
              placeholder={`Enter value for ${key}`}
            />
          </div>
        </div>
        ))}

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className='col-sm-6'>
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="statusSwitch" 
                checked={status} 
                onChange={() => setStatus(!status)}
              />
              <label className="form-check-label" htmlFor="statusSwitch">
                {status ? 'Active' : 'Inactive'}
              </label>
            </div>
          </div>
        </div>

        {/* Tampilkan pesan error jika ada */}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Row className="d-flex justify-content-center">
        <Col xs="auto">
          <div className="field">
            <Link to={`/masterdata/gradecomponents`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
            &nbsp;&nbsp;

            <button type="submit" className='btn btn-outline-success btn-sm'>
              <AiIcons.AiFillSave size={18} /> Save
            </button>

          </div>
        </Col>
        </Row>
        
      </form>
    </div>
  )
}

export default EditGradeComponent