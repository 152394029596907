/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import CPLService from './CplService';
import CourseService from '../course/CourseService';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import {  Spinner } from 'react-bootstrap';

export const CourseRelationList = () => {
  const [cpl, setCPL] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const navigate = useNavigate();
  const [course, setCourse] = useState([]);

  useEffect( () => {
    getCPLList();
    getCourseList();
  },[]);

  const getCPLList = async () => {
    const students = await CPLService.getCourseRelations();
    setCPL(students.data.data || []);
  }

  const getCourseList = async () => {
    let students = await CourseService.getAll();
    setCourse(students.data.data || []);
  }

  const deleteCPL = async (id) => {
    await CPLService.deleteCourseRelation(id);
    getCPLList();
    navigate("/masterdata/courserelation")
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
        deleteCPL(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

  const getCourseNameById = (courseId) => {
    let res = course?.find(c => c.mdl_course_id === courseId);
    return res ? res.name : 'Course not found';
  }

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (cpl.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Course Relation</h1>
          <a href="/masterdata/courserelation/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New Course Relation</span>
        </a>
        </div>
        <div><p className='fs-6 fw-semibold'>Data is empty. Please wait</p></div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    )    
  } 

  return(
    <div className='container-fluid'>
      {/* Subsection */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Course Relation</h1>
        <a href="/masterdata/courserelation/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New Course Relation</span>
        </a>
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          <th >
            No
          </th>
          <th>
            Course
          </th>
          <th>Related Course</th>   
          <th>
            Relation Type
          </th>         
          <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
          { cpl.map((category, index) => (
              <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{getCourseNameById(category?.course_id)}</td>
                  <td>{getCourseNameById(category?.related_course_id)}</td>
                  <td>{category.relation_type}</td>
                  <td>
                      {/* <Link title='Detail' to={`/masterdata/learningperiod/`+category.id}>
                        <BiIcons.BiDetail size={18} />
                      </Link> */}
                      &nbsp;&nbsp;&nbsp;
                      <Link title='Edit' to={`/masterdata/courserelation/edit/`+category.id}>
                        <AiIcons.AiFillEdit size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;

                      <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(category.id)}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
              </tr>
          )) }
        </tbody>
      </table>
      
      {/* Modal konfirmasi */}
        {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to delete this course relation?</p>
              <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        )}
      {/* Pagination Controls */}
      {/* <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                {number + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav> */}
    </div>      
  )
}
