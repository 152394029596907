import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";
import HttpProdiV2 from "../../HttpSmartProdiV2";

const apiKey = 'a60ab88e2afedd6fdf637cdc4be4c12205eb8fe644726c0b9f121b3f0a1044e9';     // Replace with your actual x-api-key


class StudentService {
    getAll() {
        try {
            return HttpProdi.get("/student");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getStudentById (studentId) {
        try {
            return HttpProdi.get("/student/"+studentId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addStudent = async(student) => {
        try {
            const studentArray = [student];
            return await HttpProdi.post("/student", studentArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    putStudent(student, studentId) {
        try {
            return HttpProdi.put("/student/"+studentId, student);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteStudent(studentId) {
        try {
            return HttpProdi.delete("/student/"+studentId,  {
                headers: {
                "X-Api-Key": apiKey, 
                "X-Client_id": 'frontend', 
                "Content-type": "application/json"
              }});
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getGrade() {
        try {
            return HttpProdi.get("/grade-component");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addGradeComponent = async(data) => {
        try {
            return await HttpProdi.post("/grade-component", data);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getGradeComponentById (id) {
        try {
            return HttpProdi.get("/grade-component/"+id);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deleteGradeComponent(studentId) {
        try {
            return HttpProdi.delete("/grade-component/"+studentId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    putGradeComponent(student, studentId) {
        try {
            return HttpProdi.put("/grade-component/"+studentId, student);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    putCategory(student, studentId) {
        try {
            return HttpProdi.put("/category/"+studentId, student);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCategory() {
        try {
            return HttpProdi.get("/category");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getStudyProgram() {
        try {
            return HttpProdiV2.get("/program-study");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCategoryById (studentId) {
        try {
            return HttpProdi.get("/category/"+studentId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    deletecategory(studentId) {
        try {
            return HttpProdi.delete("/category/"+studentId);
        } catch (error) {
            console.log(error)
            return error
        }
    }
    loginUser = (student) => {
        try {
            return  HttpProdi.post("/login", student);
        } catch (error) {
            console.log(error)
            return error
        }
    }
}

export default new StudentService();