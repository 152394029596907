/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import CPLService from './CplService';

export const EditCPL = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [status, setStatus] = useState('');
  
  const navigate = useNavigate();
  
  const {id} = useParams();
  
  useEffect( () => {
    getCPlByID();
  },[]);
  
  const getCPlByID = async () => {
    let res = await CPLService.getCPLByID(id);
    res = res.data.data
    setName(res.shortname);
    setDescription(res.description);
    setIdnumber(res.idnumber);
    setStatus(res.visible);
  }  
  
  const updateCPL = async (e) => {
    e.preventDefault();
    await CPLService.editCPLByID({
      shortname: name,
      description: description,
      idnumber: idnumber,    
      visible: status ? 1 : 0
    }, id);
    navigate("/masterdata/competency/cpl")
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/competency/cpl'>CPL Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit CPL</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateCPL }>        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Short Name</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="short name cpl"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ description } 
            onChange={ (e) => setDescription(e.target.value) }
            placeholder="description"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">CPL ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ idnumber } 
            onChange={ (e) => setIdnumber(e.target.value) }
            placeholder="id number cpl"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className='col-sm-6'>
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="statusSwitch" 
                checked={status} 
                onChange={() => setStatus(!status)}
              />
              <label className="form-check-label" htmlFor="statusSwitch">
                {status ? 'Active' : 'Inactive'}
              </label>
            </div>
          </div>
        </div>

        <div className="field d-flex justify-content-center">
          <Link to={`/masterdata/competency/cpl`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateCPL}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>

      </form>
    </div>
  )
}
