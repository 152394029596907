import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';
import CPLService from './CplService';

export const AddLearningPeriod = () => {
  const [name, setName] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');

  const navigate = useNavigate();
  
  const saveCPL = (e) => {
    e.preventDefault();
    CPLService.addLearningPeriod({
      name: name,
      start_date: startDate,
      end_date: endDate,
      }).then((resp) => {
        if (resp?.response?.status == 400){
          alert(resp.response.data.message)
        }

        if (resp?.response?.status == 500){
          alert(resp.response.data.message)
        }

        if (!resp.data.error) {
          alert("Successfully add data new learningperiod!")
          navigate("/masterdata/learningperiod");
        } 
      });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/learningperiod'>Learning Period</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New learningperiod</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ saveCPL }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="Tahun pembelajaran 2024/2025 Semester 1"
            required/>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Start date</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ startDate } 
            onChange={ (e) => setstartDate(e.target.value) }
            placeholder="31 Agustus 2024"
            required/>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">End Date</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ endDate } 
            onChange={ (e) => setendDate(e.target.value) }
            placeholder="31 Januari 2025"
            required/>
          </div>
        </div>


        <div className="field">
          <Link to={`/masterdata/learningperiod`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;

          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>

        </div>
        
      </form>
    </div>
  )
}
