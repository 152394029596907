import React from "react"
import {Sidebar} from './Sidebar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {Footer} from './Footer';
import { FaHome } from 'react-icons/fa';
import { isTokenExpired, removeCookiesData } from '../utils/utils';

export const LayoutDashboard = ({ children }) => {
    let token = Cookies.get('token');
    const navigate = useNavigate(); 
    useEffect(() => {
        const username = Cookies.get('username');
        if (!username) {
            alert("Tolong login terlebih dahulu!")
            navigate('/login'); // Redirect ke halaman "/"
            return; 
        }

        if (isTokenExpired(token)) {
            removeCookiesData();
            alert("token expired!")
            
            navigate('/login'); // Redirect ke halaman "/"
            return 
        }
    }, [navigate]); 

    return(
        <div id="wrapper" style={{ display: 'flex' }}>
        <div className="sidebar" style={{ flex: '0 0 100px' }}>
            <Sidebar />
        </div>
        <div id="content-wrapper" style={
            { flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div id="content">
                {/* component childern */}
            {children}
            </div>
            <Footer/>
        </div>
    </div>
    )
}

// Layout404 - layout not found
export const Layout404 = (props) => {
    return(
        <>
            {props.children}
            <Footer/>
        </>
    )
}

export const Page404 = () => {
    return (
        <section className="flex items-center h-screen p-16 bg-gray-50 dark:bg-gray-800">
            <div className="container mx-auto flex flex-col items-center justify-center px-5 text-center">
                <div className="max-w-md">
                    <h2 className="font-extrabold text-9xl text-purple-600 animate-pulse">
                        404
                    </h2>
                    <p className="text-2xl md:text-3xl font-semibold text-gray-700 dark:text-gray-300 mt-4">
                        Oops! Page not found.
                    </p>
                    <p className="mt-2 text-gray-500 dark:text-gray-400">
                        The page you are looking for doesn't exist or has been moved.
                    </p>
                    <a href="/" className="mt-6 inline-flex items-center px-6 py-3 text-lg font-medium text-gray-50 bg-purple-600 rounded-md shadow-md hover:bg-purple-700 transition duration-300">
                        <FaHome className="mr-2" />
                        Back to Homepage
                    </a>
                </div>
            </div>
        </section>
    );
};