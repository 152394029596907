/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import CPLService from './CplService';

export const EditCourseRelation = () => {
  const [name, setName] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [status, setstatus] = useState('');
  
  const navigate = useNavigate();
  
  const {id} = useParams();
  
  useEffect( () => {
    getCPlByID();
  },[]);
  
  const getCPlByID = async () => {
    let res = await CPLService.getCourseRelationByID(id);
    res = res.data.data
    setName(res.course_id);
    setstartDate(res.related_course_id);
    setendDate(res.relation_type);
  }  
  
  const updateCPL = async (e) => {
    e.preventDefault();
    await CPLService.editCourseRelation({
      course_id: name,
      course_related_id: startDate,
      relation_type: endDate,    
    }, id);
    navigate("/masterdata/courserelation")
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/masterdata/courserelation'>Course relation Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Courserelation</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateCPL }>        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="course id"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Related Course ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ startDate } 
            onChange={ (e) => setstartDate(e.target.value) }
            placeholder="related course id"
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Relation Type</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ endDate } 
            onChange={ (e) => setendDate(e.target.value) }
            placeholder="end date"
          /></div>
        </div>


        <div className="field d-flex justify-content-center">
          <Link to={`/masterdata/courserelation`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateCPL}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>

      </form>
    </div>
  )
}
