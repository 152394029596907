import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import LecturerService from './LecturerService';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const AddManager = () => {
  const [lecturer_id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [username, setuUername] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [middle_name, setMiddle_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [study_program, setStudy_program] = useState('');
  const [academic_position, setAcademic_position] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);


const saveLecturer =  (e) => {
  e.preventDefault();
  LecturerService.addManager({
    lecturer_id: lecturer_id,
    username: lecturer_id,
    password: password, 
    first_name: first_name,
    middle_name: middle_name,
    last_name: last_name,    
    affiliation: affiliation,
    study_program: study_program,
    academic_position: academic_position,
    email: email,
    phone_number: phone_number
  }).then((resp) => {
    if (resp.data.status_code == 201) {
      alert("Successfully add data manager!")
      navigate("/manager");
    }     

    if (resp?.response?.status == 400){
      alert(resp.response.data.message)
    }

    if (resp?.response?.status == 500){
      alert(resp.response.data.message)
    }

  });
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/manager'>Managers Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Manager</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={ saveLecturer }>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Lecturer National ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ lecturer_id } 
            onChange={ (e) => setId(e.target.value) }
            placeholder="Lecturer National ID (NIDN)"
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Username</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ lecturer_id } 
            onChange={ (e) => setuUername(e.target.value) }
            placeholder="username (NIDN)"
            readOnly

          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Password</label>
          <div className='col-sm-6 '>
            <div className='input-group'>
            <input 
            type={showPassword ? "text" : "password"}
            className="form-control"
            value={ password } 
            onChange={ (e) => setPassword(e.target.value) }
            placeholder="password"
            required
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
          </button>
            </div>
    
          </div>
        </div>
          
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Academic Position</label>
          <div className='col-sm-2'>
            <select className='form-select'  value={ academic_position } onChange={ (e) => setAcademic_position(e.target.value) }>
              <option>-Select Position-</option>
              <option value="admin">Admin</option>
              <option value="Assistant Professor">WRAM</option>
              <option value="Associate Professor">Kaprodi</option>
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Full Name</label>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ first_name } 
              onChange={ (e) => setFirst_name(e.target.value) }
              placeholder="First Name"
              required
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ middle_name } 
              onChange={ (e) => setMiddle_name(e.target.value) }
              placeholder="Middle Name"
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ last_name } 
              onChange={ (e) => setLast_name(e.target.value) }
              placeholder="Last Name"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Affiliation</label>
          <div className='col-sm-2'>
            <select className='form-select'  value={ affiliation } onChange={ (e) => setAffiliation(e.target.value) }>
            <option>-Select Affiliation-</option>
              <option value="UKTS">UKTS</option>
              <option value="ITB">ITB</option>
              <option value="UNS">UNS</option>
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Department / Study Program</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ study_program } 
            onChange={ (e) => setStudy_program(e.target.value) }
            placeholder="Study Program"
            required
          /></div>
        </div>  
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Email Address</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ email } 
            onChange={ (e) => setEmail(e.target.value) }
            placeholder="email@gmail.com"
            required
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Phone Number</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ phone_number } 
            onChange={ (e) => setPhone_number(e.target.value) }
            placeholder="Phone Number: 081234567890"
            required
          /></div>
        </div>        
        <div className="field">
          <Link to={`/lecturer`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          {/* <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveLecturer}><AiIcons.AiFillSave size={18} />Save</Link>
        */}
          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>

        </div>
        <div className="row mb-3">
          &nbsp;
        </div>
      </form>
    </div>
  )
}

export default AddManager