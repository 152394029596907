import React from 'react';
import logo from '../assets/resized_smart_learning_system.png';
import { removeCookiesData } from '../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';



function Homepage() {
  return (
    <div className='container-fluid'>
        <img src={logo} className='img-fluid' alt='' />
    </div>      
  )
}

export default Homepage


export const Logout = () => {
  const navigate = useNavigate();
  toast.warn("Logging out..");
  removeCookiesData();
  setTimeout(() => navigate("/login"), 2000);
  return (
    <>
    <ToastContainer /></>
  )
}