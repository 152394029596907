/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CourseService from './CourseService';
import StudentService from '../student/StudentService';
import * as AiIcons from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Breadcrumb, Tab, Tabs, Badge  } from 'react-bootstrap';
import '../../App.css';

const ViewCourseCatalog = () => {
  const [name_id, setName_id] = useState('');
  const [desc, setDesc] = useState('');
  const [credit, setCredit] = useState('');
  const [courseCode, setcourseCode] = useState('');
  const [status, setStatus] = useState('');
  const [courseStartTime, setCourseStartTime] = useState('');
  const [courseEndTime, setCourseEndTime] = useState('');
  const [createdAt, setcreatedAt] = useState(); // Popup state
  const [updatedAt, setupdatedAt] = useState();

  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false); // Popup state

  const {id} = useParams();
  const navigate = useNavigate();
  useEffect( () => {
    getCourseById();
  },[]);

  const getCourseById = async () => {
    let res = await CourseService.getCourseCatalogById(id);
    res = res.data

    setName_id(res.data.name);
    setDesc(res.data.description);
    setCredit(res.data.course_credit);
    setStatus(res.data.status);
    setcreatedAt(res.data.created_at);
    setupdatedAt(res.data.updated_at);
    setcourseCode(res.data.course_code);

    const courseStartDate = res.data.course_start_date;// Convert timestamp ke millisecond dan buat Date object
    const date = new Date(courseStartDate * 1000);
    
    const courseEndDate = res.data.course_end_date;
    const dateEnd = new Date(courseEndDate * 1000);
    
    // Menggunakan metode toLocaleString untuk mengonversi ke zona waktu Indonesia (WIB) -
    const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',  };
    const indonesiaTime = date.toLocaleString('id-ID', options);
    setCourseStartTime(indonesiaTime)
    const indonesiaTimeEnd = dateEnd.toLocaleString('id-ID', options);
    setCourseEndTime(indonesiaTimeEnd)
  }

  const getGradeComponentNameById = ( id, data) => {
    const item = data.find(entry => entry.id === id);
    return item ? item.name : ``;
  };

  const deleteCourse = async (id) => {
    await CourseService.deleteCourseCatalog(id);
    // setShowConfirm(false); // Close popup
    navigate('/catalogcourse');
  }

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteCourse(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true); // Show popup when delete button is clicked
  };

  const handleCancelDelete = () => {
    setShowConfirm(false); // Close popup without deleting
    setSelectedCourseId(null);
  };


  // Inline styles for popup modal
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };
  
  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/course'>Courses Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Course Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={8}>{name_id}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Description :</b></Col>
            <Col xs={8}>{desc}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Credit :</b></Col>
            <Col xs={8}>{credit}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Code :</b></Col>
            <Col xs={8}>{courseCode}</Col>
          </Row>

        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Created at :</b></Col>
            <Col xs={8}>{createdAt}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Updated at :</b></Col>
            <Col xs={8}>{updatedAt}</Col>
          </Row>
        </Col>

      </Row>

        <Row><Col>&nbsp;</Col></Row>
        <Row className="d-flex justify-content-center">
          <Col xs="auto">
            <Link to={`/catalogcourse`} className='btn btn-outline-dark btn-sm'><AiIcons.AiOutlineRollback size={18} />Back</Link>
            &nbsp;&nbsp;

            <Link to={`/catalogcourse/edit/`+id} className='btn btn-outline-primary btn-sm'><AiIcons.AiFillEdit size={18} />Edit</Link>
            &nbsp;&nbsp;

            <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(id)}>
              <AiIcons.AiTwotoneDelete size={18} /> Delete
            </Link>
          </Col>
        </Row>
      </Container>

      
      {/* Modal konfirmasi */}
      {showConfirm && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <p>Are you sure you want to delete this course?</p>
            <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
            <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ViewCourseCatalog
