import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as AiIcons from 'react-icons/ai';
import StudentService from '../student/StudentService';
import Cookies from "js-cookie";
import { decodeJWT } from '../../utils/utils';
import './FormLogin.css'; // Import file CSS
import {Footer} from '../../layout/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const FormLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const saveStudent = async (e) => {
    e.preventDefault();
    try {
      let resp = await StudentService.loginUser({ username, password });

      if (resp.status === 200) {
        resp = resp.data.data;
        Cookies.set('token', resp.token);
        Cookies.set('username', username);
        let { roleId, mdlId } = decodeJWT(resp.token);
        if (username == 'admin') {
          roleId = 1
          mdlId= 1 
        }
        Cookies.set('role_id', roleId);
        Cookies.set('mdl_id', mdlId);
        Cookies.set('redirected_mdl_url', resp.redirected_mdl_url);
        toast.success("Successfully logged in!");
        setTimeout(() => navigate("/dashboard-sso"), 2000);
      } else {
        toast.error("Login failed, please check your credentials.");
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 500) {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <>
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Welcome to Smart Learning System!</h2>
        <form onSubmit={saveStudent} className="login-form">
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter username"
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
            />
          </div>
          <button type="submit" className="btn login-button">
            <AiIcons.AiFillSave size={18} /> Login
          </button>
        </form>
      </div>
    </div>
      <Footer/>
      <ToastContainer />
    </>
  );
}
