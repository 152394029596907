import HttpMoodle from "../HttpMoodle";

class MoodleService {
    getUsers() {  
        return HttpMoodle.post('', '', {
            params: {
                // ...API_DEFAULT_PARAMS,
                'wsfunction': 'core_user_get_users',
                'criteria[0][key]': 'email',
                'criteria[0][value]': "%@%"
            }
        })
    }

    addUser(username, firstname, lastname, email) {
        //mdl_user
        return HttpMoodle.post('','', {
            params: {
                'wsfunction': 'core_user_create_users',
                'users[0][username]': username,
                'users[0][auth]': 'manual',
                'users[0][password]': 'Sm@rtClas5',
                'users[0][firstname]': firstname,
                'users[0][lastname]': lastname,
                'users[0][email]': email,
                'users[0][maildisplay]': 0,
                'users[0][country]': 'ID',
                'users[0][timezone]': 'Asia/Jakarta',
                'users[0][lang]': 'en',
                'users[0][calendartype]': 'gregorian'
            }
        })
    }

    assignUserRole(roleid, userid) {
        //mdl_role_assignments 
        return HttpMoodle.post('','', {
            params: {
                'wsfunction': 'core_role_assign_roles',
                'assignments[0][roleid]': roleid,
                'assignments[0][userid]': userid,
                'assignments[0][contextid]': 2
            }
        })
    }

    addCategory(name, description) {
        //mdl_course_categories
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'core_course_create_categories',
                'categories[0][name]': name,
                'categories[0][parent]': 0,
                'categories[0][description]': description,
                'categories[0][descriptionformat]': 1
            }
        })
    }

    addCourse(categoryid,idnumber,visible,fullname,summary,startdate,numsections) {
        //mdl_course
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'core_course_create_courses',
                'courses[0][categoryid]': categoryid,
                'courses[0][idnumber]': idnumber,
                'courses[0][visible]': visible,
                'courses[0][fullname]': fullname,
                'courses[0][summary]': summary,
                'courses[0][startdate]': startdate,
                'courses[0][numsections]': numsections,
                'courses[0][shortname]': fullname.split(' ').join('_'),
                'courses[0][summaryformat]': 1,
                'courses[0][format]': 'weeks',
                'courses[0][showgrades]': 1,
                'courses[0][showreports]': 1,
                'courses[0][enablecompletion]': 1,
                'courses[0][completionnotify]': 1
            }
        })
    }

    enrolCourse(courseid, userid, role) {
        //mdl_enrol, mdl_user_enrolments
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'enrol_manual_enrol_users',
                'enrolments[0][courseid]': courseid,
                'enrolments[0][userid]': userid,
                'enrolments[0][roleid]': role //teacher
            }
        })
    }

    unenrolCourse(userid, courseid) {
         //mdl_enrol, mdl_user_enrolments
         return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'enrol_manual_unenrol_users',
                'enrolments[0][courseid]': courseid,
                'enrolments[0][userid]': userid
            }
        })
    }

    getEnrolledUserByCourse(courseid) {
        //mdl_user_enrolments
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'core_enrol_get_enrolled_users',
                'courseid': courseid
            }
        })
    }

    getCourseById(courseid) {
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'core_course_get_courses',
                'options[ids][0]': courseid
            }
        })
    }

    updateCourse(courseid, visible) {
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'core_course_update_courses',
                'courses[0][id]': courseid,
                'courses[0][visible]': visible
            }
        })
    }

    deleteCourse(courseid) {
        return HttpMoodle.post('','',{
            params: {
                'wsfunction': 'core_course_delete_courses',
                'courseids[0]': courseid                
            }
        })
    }
}

export default new MoodleService();