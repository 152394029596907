import React, {useState, useEffect} from 'react';
import { Link , useParams} from 'react-router-dom';
import CourseService from '../course/CourseService';

import * as BiIcons from 'react-icons/bi';
import { Box, Typography } from '@mui/material';
import { Breadcrumb} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// attendance list
export const AttendanceList = () => {
  const [totalCourse, setTotalCourse] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10; // Jumlah item per halaman
  const [attendances, setAttendance] = useState([]);
  const [coursename, setCoursename] = useState('');
  const [courseidnumber, setCourseidnumber] = useState('');
  const [enrolledLecturer, setEnrolledLecturer] = useState([]);

  const {courseid} = useParams();

  useEffect( () => {
    getGrades();
    getEnrollments();
    if (courseid) {
      getCourseById();
    }
  },[]);

  const getGrades = async () => {
    let coursesGrades = await CourseService.getCourseAttendance(courseid, 0);
    coursesGrades = coursesGrades.data.data 
    setAttendance(coursesGrades || []);
  }

  const getEnrollments = async () => {
    let coursesGrades = await CourseService.getCourseEnrollment(courseid);
    coursesGrades = coursesGrades.data.data 

    // Filter untuk lecturer dengan roleid 3
    setEnrolledLecturer(coursesGrades.filter((enrollment) => 
      enrollment.roles.some(role => role.roleid === 3)
    ));
  }

  const getCourseById = async () => {
    let res = await CourseService.getCourseById(courseid);
    res = res.data.data
    setCoursename(res.name)
    setCourseidnumber(res.course_id_number)
  }

  // Hitung total halaman
  const totalPages = Math.ceil(attendances.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentCourses = attendances.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  const getLecturersNames = (lecturers) => {
    const lecturerNames = lecturers.map(lecturer => lecturer.fullname);
    
    return `${lecturerNames.join(', ')}`;
  };


  if (attendances.length === 0) {
    return (
      <div className='container-fluid'>
        <Breadcrumb>
          <Breadcrumb.Item href='/grade'>Grade</Breadcrumb.Item>
          <Breadcrumb.Item active>Grade List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Courses Grade</h1>
        </div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    );
  }

  return (
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/attendancecourse'>Course List</Breadcrumb.Item>
          <Breadcrumb.Item active>Attendance List</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
      </div>

      <Container>
      <Row> 
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={8}>{coursename}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Code :</b></Col>
            <Col xs={8}>{courseidnumber}</Col>
          </Row>
        
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Lecturer :</b></Col>
            <Col xs={8}>{getLecturersNames(enrolledLecturer)}</Col>
          </Row>
        </Col>
      </Row>
      </Container>
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              Course Attendance List
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
              {totalCourse}
          </Typography>
      </Box>

      <table className='table table-sm'>
      <thead>
        <tr>
          <th>No</th>
          <th>Class Date</th>
          <th>Class Schedule</th>
          <th>Class Start Time</th>
          <th>Class Duration (Minutes)</th>
          <th>Number Participant present</th>
          <th>Detail</th>
        </tr>
      </thead>

      <tbody className='table-group-divider'>
        {currentCourses.map((attendance, index) => {
          return (
            <tr key={attendance.courseid}>
              <td>{index + 1}</td>
              <td>{attendance.class_date}</td>
              <td>{attendance.class_schedule}</td>  
              <td>{attendance.class_start_time}</td>
              <td>{attendance.duration / 60}</td>  
              <td>{attendance.number_present}</td>             
              <td>
                <Link title='Detail' to={`/attendancecourse/${attendance.mdl_course_id}/${attendance.session_id}`}>
                  <BiIcons.BiDetail size={18} />
                </Link>&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>

    {/* Pagination Controls */}
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        </li>
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </li>
      </ul>
    </nav>

    </div>
  )
}
